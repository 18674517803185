@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{
  font-family: "Poppins", sans-serif;
}

.hoverbtn{
  transition: all 200ms ease-in;
  overflow: hidden;
}
.hoverbtn h1, .hoverbtn p{
  z-index: 2;
  position: relative;
  transition: all 200ms ease-in;
}
.hoverbtn::before{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate3d(-100%, 0, 0);
  background: linear-gradient(to left, #0057FF, #357AFF);
  z-index: 1;
  transition: all 200ms ease-in;
}
.hoverbtn:hover h1, .hoverbtn:hover p {
  color: white !important;
}
.hoverbtn:hover:before {
  transform: translate3d(0, 0, 0);
}

/* key frame for arrow btn  */
#pot {
  bottom: -15px;
  position: absolute;
  animation: linear infinite;
  animation-name: run;
  animation-duration: 2s;
}
@keyframes run {
  0% {
    right: 0;
  }
  50% {
    right: 100%;
  }
  100% {
    right: 0;    
  }
}
/* key frame for arrow btn  */

/* scroll css  */
/* PersonalLoan.css */

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOutLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.animate-start {
  animation: slideInRight 1s forwards;
}

.animate-end {
  animation: slideOutLeft 1s forwards;
}




.scaled-image {
    /* Scale down the image to 80% of its original size in normal state */
    transform: scale(0.5);
    transform-origin: right bottom;
    position: absolute;
    transition: transform 0.3s ease-in-out;
}

.servicediv:hover .scaled-image{
    /* Scale the image back to its original size on hover */
    transform: scale(1);
}
.servicediv{
  transition: transform 0.3s ease, box-shadow 0.3s ease;

}
.servicediv:hover{
  transform: translateY(-30px);
}
/* scroll css  */

.bottom_whtsapp{
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99999;
}


/* Styling for sticky sections */
.sticky-section {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0px; /* Space from the top of the viewport */
  transition: top 0.5s ease;
}



/* titel style */
.title {
  display: table;
  width: 62%;
  margin: 0 auto 60px;
  padding: 0px 80px 30px;
  position: sticky;
  top: 150px;
  text-align: center;
}
.title h2{
  margin-top: -50px;
}

.title:before, .title:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 2px;
  z-index: 2; /* Ensure title is above cards initially */

  height: 100%;
  background: #0057FF;
}
.title p {
  margin: 0;
  padding: 20px 0; /* Adjust padding to create space around the paragraph */
  font-size: 1.25em;
}

.title > p {
  color: #4e4d4d;
  float: left;
  font-family: arimo;
  font-size: 14px;
  width: 100%;
  margin-top: 10px;
  line-height: 20px;
  padding-bottom: 100px;
}
.title h2:before, .title h2:after {
  background: none repeat scroll 0 0 #0057FF;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  top: 0;
  width: 150px;
}

.title h2:after{
  background: linear-gradient(45deg, #0057FF, #0057FF);
}
.title h2:after {
  left: auto;
  right: 0;
}
.title:after{
  background: linear-gradient(45deg, #0057FF, #0057FF);
}
.title > p:before, .title > p:after {
  background: #0057FF;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50.5%;
  height: 2px;
  
}
.title > p:after{
  background: linear-gradient(45deg, #0057FF, #0057FF);
}
.title > p:after {
  left: auto;
  right: 0;
}
.title:after {
  left: auto;
  right: 0;
}

.firstcard{
  margin-top: -9em;
    z-index: 9;
    position: relative;
}
.sticky {
    position: sticky !important;
}

.thirdsectionpart{
  margin-top: -15em;
}

.newcard{
  background: linear-gradient(to top, #E6F6FF, #bbe6fc);
  color: #20243A;
}
.newcard:hover{
  background: linear-gradient(90deg, rgba(2,30,73,1) 0%, rgba(0,0,0,1) 53%, rgba(10,10,10,1) 100%);
  color: #fff;
}
/* mobile Screen responsive */

@media screen and (max-width: 767.97px) {
  .thirdsectionpart{
    margin-top: -8em;
  }
  .title h2:before, .title h2:after {
    width: 70px;
}
.title{
  width: 100%;
}
.title h2 {
  margin-top: -29px;
}
.title > p:before, .title > p:after {
  content: none;
}
.title:before, .title:after{
  content: none;
}
.footer_img {
  left: 0;
  top: -13em;
}
.footer_img img{ 
  margin: 0 auto;
}
}
.slick-prev:before {
  content: '←';
  color: rgb(0, 87, 255);
}
.slick-next:before {
  content: '→';
  color: rgb(0, 87, 255);
}
.slick-slide {
  padding-left: 5px;
}
.white-dot {
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

/* carusal */
@media (min-width: 400px) {
  .frame {
    min-width: 50%;
    max-width: 50%;
  }
}
@media (min-width: 768px) {
  .frame {
    min-width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
@media (min-width: 1040px) {
  .frame {
    min-width: 25%;
    max-width: 25%;
  }
}


.slick-prev:before, .slick-next:before {
  color: blue !important;
}


/* emi  */
.calBox {
  width: 40%;
}
.EMIBox {
  display: flex;
  justify-content: space-around;
  background: var(--ebf-4-ff, #ebf4ff);
  padding: 4rem;
}
.resBox {
  border-radius: 13px;
  background: #fff;
  box-shadow: 0px 4px 10.9px 0px rgba(132, 184, 255, 0.60);
  width: 25rem;
  /* height: 25rem; */
}
.mothlyStyle {
  color: var(--575-b-6-d, #575b6d);
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 3rem 0 0 2rem;
}
.mothlyStyle .resStyle {
  color: var(--Blue-2, #367bff);
  font-family: Poppins;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Interest {
  color: var(--575-b-6-d, #575b6d);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Interest .val {
  color: var(--neutral, #20243a);
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.resbox {
  display: flex;
  justify-content: space-around;
  padding: 3rem 15px 0px;
}
.Interest{
  padding-left: 10px;
}
.loanAmt {
  color: var(--neutral, #20243a);
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.loanTxt {
  color: var(--6-f-7387, #6f7387);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.head {
  color: var(--575-b-6-d, #575b6d);
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.subHead {
  color: var(--8-e-92-a-5, #8e92a5);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 10px;
}
.h {
  color: var(--neutral, #20243a);
  margin-top: 4rem;
  text-align: center;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
@media (max-width: 768px) {
  .EMIBox {
    margin: 10px;
    padding: 1rem;
    flex-direction: column;
 }
  .calBox {
    width: fit-content;
 }
  .resBox {
    width: 100%;
    margin: 0 auto;
 }
  .head {
    display: none;
 }
  .subHead {
    display: none;
 }
  .h {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    padding-bottom: 20px;
    text-align: center;
    margin-top: 4rem;
 }
}

h2 {
  color: #333;
}

label {
  display: block;
  margin-top: 10px;
}


.input-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

input {
  margin-top: 5px;
  padding: 8px; 
  flex: 1; 
  box-sizing: border-box; 
  border: 1px solid #ccc; 
  border-radius: 4px;
}

button {
  margin-top: 10px;
  padding: 12px;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

button:hover {
  background-color: #45a049;
}

.result {
  margin-top: 20px;
}

h3 {
  color: #333;
}

h1 {
  color: #154379;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 44px;
}

@media (max-width: 768px) {
  .input-group {
    flex-direction: column;
    align-items: stretch;
  }

  input {
    flex: none; 
    width: 100%;
  }
}

/* emi  */


.personal_back{
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}
.one_two_three{
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 100px;
}

.marquee-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  font-size: 0.875rem; /* 14px */
  padding: 0.625rem; /* 10px */
  font-weight: 600;
}
.white-dot {
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 50%;
  margin: 0 1rem; /* Adjust spacing as needed */
}
.hover_non:hover{
  background-color: white !important;
}