
.wrapper {
  margin: 0 auto;
  margin-bottom: 2em;
}

.scroll-cards {
  counter-reset: card;
  position: relative;
  display: block;
  padding-bottom: 2em;
}

.scroll-cards > .scroll-cards__item + .scroll-cards__item {
  margin-top: 40vh;
}

.scroll-cards__item {
  --offset: 0.5em;
  color: #000;
  position: sticky;
  top: max(16vh, 15em);
  min-height: 19em;
  /* background: #fff; */
  /* box-shadow: 0 2px 40px rgba(0, 0, 0, 0.1); */
  width: calc(100% - 0 * var(--offset));
}

h2 {
  font-size: 1.25em;
  text-transform: uppercase;
  margin: 0;
}

p {
  font-size: 1.25em;
  line-height: 1.5;
}

/*
If you use SASS you can shorten this one here like this:

@for $i from 0 through 5 {
  &:nth-of-type(#{$i}) {
    transform: translate(calc((#{$i} - 1) * var(--offset)), calc((#{$i} - 1) * var(--offset)));
  }
}
*/
.scroll-cards__item:nth-of-type(1) {
  transform: scale(0.8);
  top: max(16vh, 15em);
}
.scroll-cards__item:nth-of-type(2) {
  transform: scale(0.9);
  top: max(16vh, 18em);

}
.scroll-cards__item:nth-of-type(3) {
  /* transform: scal translate(calc((1 - 1) * var(--offset))); */
  top: max(16vh, 22em);
  transform: translate(calc((0 - 0) * var(--offset)), calc((6 - 1) * var(--offset)));

}






